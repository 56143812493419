<template>
    <iframe :src="`https://hris.ardentnetworks.com.ph/?sid=${getdata.hris_token}`" class="flex-1"></iframe>
    
</template>

<script>
export default {
    computed: {
        getdata() {
            let data = {};
            try {
                var json = JSON.parse(localStorage.getItem('ardentpmp_data'));
                if(Object.keys(json).length) {
                    data = json;
                    //https://hris.ardentnetworks.com.ph/
                    //https://localhost/hrisv3/
                }
            }
            catch(e) {
                return {};
            }

            return data;
        }
    },
    mounted(){
        console.log(this.getdata.hris_token)
    }
}
</script>

<style scoped>

</style>